import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { kebabCase, isString } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CssBaseline from '@material-ui/core/CssBaseline'
import Chip from '@material-ui/core/Chip'
import { Carousel } from 'react-responsive-carousel'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  useTheme,
} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CustomPageContext from '../Context/CustomPageContext'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import AboutSection from '../components/AboutSection'
import MetaTags from '../components/MetaTags'
import appConfig from '../config/config'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

export const AboutPageTemplate = ({
  helmet,
  title,
  profileImage,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content
  const theme = useTheme()
  const classes = useStyles()

  return (
    <section className={classes.root}>
      {helmet || ''}
      <AboutSection title={title} profileImage={profileImage}>
        <PageContent content={content} />
      </AboutSection>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  helmet: PropTypes.node,
  title: PropTypes.string.isRequired,
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ location, data }) => {
  const { markdownRemark: post, site } = data
  const initUiTheme =
    typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(
    initUiTheme || appConfig.defaultInitTheme
  )

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: prefersDarkMode ? 'dark' : 'light',
          type: uiTheme,
          primary: { main: '#212121' },
          secondary: { main: '#DD2C00' },
        },
      }),
    [uiTheme]
  )

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <AboutPageTemplate
            contentComponent={HTMLContent}
            title={post.frontmatter.contentTitle}
            profileImage={post.frontmatter.profileImage}
            content={post.html}
            helmet={
              <>
                <MetaTags
                  pageTitle={post.frontmatter.title}
                  pageDescription={post.frontmatter.description}
                />
              </>
            }
          />
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.node,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        contentTitle: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        contentTitle: PropTypes.string,
        siteUrl: PropTypes.string,
      }),
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        description
        profileImage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contentTitle
      }
    }
  }
`
