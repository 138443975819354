import React from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import GitHubIcon from '@material-ui/icons/GitHub'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  aboutTitleSection: {
    padding: '1rem 0rem',
  },
  aboutTitle: {
    background: theme.palette.getContrastText(theme.palette.background.default),
    transform: 'skew(-15deg)',
    color: theme.palette.getContrastText(
      theme.palette.getContrastText(theme.palette.background.default),
    ),
    padding: '0.5rem 1rem',
    width: 'fit-content',
    boxShadow: '5px 5px 0px 1px grey',
  },
  aboutContentSection: {
    background: '#424242',
    padding: '1rem 1rem',
    boxShadow: '10px 10px 0px 1px grey',
  },
  aboutImageSection: {
  },
  aboutImage: {
  },
  aboutContent: {
    padding: '1rem 1rem',
    color: 'white',
  },
  aboutContentTitle: {
    fontSize: '1.6rem',
  },
  aboutContentDescription: {
    fontSize: '1rem',
  },
  aboutContentFooter: {

  },
  linksContainer: {
    color: '#aeaeae',
    padding: '1rem 0rem',
    width: '11rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  }
}))

const AboutSection = ({ title, profileImage, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.aboutTitleSection}>
        <Typography variant="h5" className={classes.aboutTitle}>
          About
        </Typography>
      </div>
      <Grid
        container
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={11}
          md={8}
          lg={7}
          xl={6}
        >
          <div className={classes.aboutContentSection}>
            <Grid
              container
            // justify="center"
            // alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                lg={4}
                xl={3}
              >
                <div className={classes.aboutImageSection}>
                  {profileImage && (
                    <PreviewCompatibleImage
                      className={classes.aboutImage}
                      imageInfo={{
                        image: profileImage,
                        alt: 'creator-profile-pic',
                        style: { width: '100%' },
                      }}
                    />
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs
                sm
                md
                lg
                xl
              >
                <div className={classes.aboutContent}>
                  <Typography variant="h4" className={classes.aboutContentTitle}>
                    {title}
                  </Typography>
                  <div className={classes.aboutContentDescription}>
                    {children}
                  </div>
                  <div className={classes.aboutContentFooter}>
                    <div className={classes.linksContainer}>
                      <a
                        href="https://www.instagram.com/in_solitud"
                        style={{ color: 'unset', textDecoration: 'none' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon fontSize="large" />
                      </a>
                      <a
                        href="https://github.com/nilaysavant"
                        style={{ color: 'unset', textDecoration: 'none' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GitHubIcon fontSize="large" />
                      </a>
                      <a
                        href="https://in.linkedin.com/in/nilay-savant"
                        style={{ color: 'unset', textDecoration: 'none' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedInIcon fontSize="large" />
                      </a>
                      <a
                        href="https://twitter.com/nilay_savant"
                        style={{ color: 'unset', textDecoration: 'none' }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterIcon fontSize="large" />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

AboutSection.propTypes = {
  title: PropTypes.string,
  profileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
}

AboutSection.defaultProps = {
  title: 'AboutSection Title',
  children: null,
}

export default AboutSection
